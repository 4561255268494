// Translated
<template>
  <div>
    <ClientOnly>
      <template #default>
        <Loading v-if="loading" />
        <template v-else>
          <slot />
        </template>
      </template>
      <template #fallback>
        <div class="text-center my-12">
          <Loading />
          <p data-i18n="bookLoadingMessageSearchingForRooms">
            {{ $t('bookLoadingMessageSearchingForRooms') }}
          </p>
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import cognitoMixin, { createAuthFeedback } from '@layers/web/mixins/cognito.mixin'
import contexts from '@layers/web/constants/contexts'
import { AuthPages } from '@layers/web/utils/auth/auth'

export default defineNuxtComponent({
  mixins: [cognitoMixin],

  data () {
    return {
      loading: true,
      timer: null,
    }
  },

  computed: {
    ...mapState(useUserStore, ['getToken', 'isLoggedIn']),
    ...mapState(useLocaleStore, ['canLocaleLogin']),
  },

  created () {
    if (!this.canLocaleLogin) {
      this.$router.push('/').catch(() => {})
    }

    this.loading = !this.isLoggedIn

    if (!process.browser) {
      return
    }

    this.timer = window.setTimeout(() => {
      this.loading = !this.isLoggedIn

      if (!this.isLoggedIn) {
        this.setFeedback(createAuthFeedback(
          this.$t('memberLogInAgain'),
          'loggedOut',
          contexts.info
        ))
        this.$router.push(this.authLinkTo(AuthPages.login)).catch(() => {})
      }
    }, 2000)
  },
})
</script>
