// Translated
// Migrated
<template>
  <AuthGuard class="favourites-container main-content mt-12">
    <div
      v-if="loading"
      class="flex flex-wrap -mx-4"
    >
      <div class="w-12/12 px-4">
        <HorizontalCardWithImageSkeleton
          v-for="i in 3"
          :key="`skeleton-${i}`"
        />
      </div>
    </div>
    <div
      v-else
      class="flex flex-wrap -mx-4"
    >
      <NotFound v-if="bookings.length === 0">
        <span data-i18n="membersNoBookingsYet">
          {{ $t('membersNoBookingsYet') }}
        </span>
        <span class="block">
          {{ $user?.email }}
        </span>
      </NotFound>
      <div
        v-for="booking in bookings"
        :key="`booking-${booking.url}`"
        class="w-12/12 px-4"
      >
        <MyBookingsTripCard
          :trip="booking.tripType"
          :booking="booking"
        />
      </div>
    </div>
  </AuthGuard>
</template>

<script setup>
// import { faMagnifyingGlass, faTimes } from '@fortawesome/pro-duotone-svg-icons'

const localeStore = useLocaleStore()
const userStore = useUserStore()

// const icons = {
//   magGlass: faMagnifyingGlass,
//   xmark: faTimes
// }

const {
  user: $user,
  getBookings: bookings,
  loading,
} = storeToRefs(userStore)

useAsyncData(() => {
  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['membersMyBookingsTitle']))
})
</script>
