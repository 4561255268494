// Translated
// Migrated
<template>
  <AuthGuard class="favourites-container main-content mt-12">
    <div class="flex flex-wrap -mx-4">
      <NotFound
        v-if="favourites.length === 0"
        class="w-12/12 px-4"
        data-i18n="charterNoFavouritesYet"
      >
        {{ $t('charterNoFavouritesYet') }}
      </NotFound>
      <div
        v-for="fav in favourites"
        :key="`favourite-${fav.id}`"
        class="w-12/12 md:w-6/12 px-4"
      >
        <Trip
          :trip="fav"
          :loading="!fav.url"
        />
      </div>
    </div>
  </AuthGuard>
</template>

<script setup>
definePageMeta({
  middleware: 'auth',
})

const userStore = useUserStore()
const localeStore = useLocaleStore()

const { favourites } = storeToRefs(userStore)

await useAsyncData(async () => {
  await userStore.listFavourites()
  await userStore.populateFavourites()

  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['favouritesTitle']))
})
</script>
