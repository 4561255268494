// Translated
// Migrated
<template>
  <div class="block">
    <div class="tripresult my-4 lg:mt-0">
      <div class="tripresult__content sm:block">
        <div class="tripresult__content__headline">
          <b-skeleton
            class="flex items-center flex-wrap pt-2"
            height="40px"
            width="250px"
          />
          <b-skeleton
            class="pb-2"
            height="25px"
            width="175px"
          />
        </div>
        <div class="tripresult__content__description my-2">
          <b-skeleton />
          <b-skeleton />
          <b-skeleton />
          <b-skeleton />
        </div>
        <div class="flex justify-between items-end">
          <div class="tripresult__details">
            <b-skeleton
              class="tripresult__details__item"
              width="140px"
              height="30px"
            />
            <b-skeleton
              class="tripresult__details__item"
              width="80px"
              height="30px"
            />
            <b-skeleton
              class="tripresult__details__item"
              width="80px"
              height="30px"
            />
          </div>
        </div>
      </div>
      <div class="tripresult__thumb">
        <b-skeleton-img class="tripresult__thumb__img">
          <div class="tripresult__thumb__cost font-bold">
            <b-skeleton />
          </div>
        </b-skeleton-img>
        <div class="tripresult__thumb__mobile-title">
          <div>
            <b-skeleton class="flex items-center flex-wrap" />
            <b-skeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@layers/web/assets/scss/modules/product/_product-trip-result";
</style>
