// Translated
// Migrated
<template>
  <div class="m-auto my-12 py-12">
    <div class="py-4 flex flex-col justify-center items-center">
      <fa-layers class="fa-5x fa-fw mb-12 pb-12">
        <fa
          :icon="icons.xmark"
          transform="up-2 left-3"
          style="--fa-secondary-color: #004b9c"
        />
        <fa
          id="magGlass"
          :icon="icons.magGlass"
          transform="grow-11"
          opacity
          style="--fa-secondary-color: #004b9c"
        />
      </fa-layers>
      <slot />
    </div>
  </div>
</template>

<script>
import { faMagnifyingGlass, faTimes } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  data () {
    return {
      icons: {
        magGlass: faMagnifyingGlass,
        xmark: faTimes,
      },
    }
  },
})
</script>

<style lang="scss" scoped>
#magGlass {
  left: -4px;
  top: 2px;

  &.svg-inline--fa .fa-secondary{
    opacity: 1
  }
}
</style>
