<template>
  <AuthGuard class="settings-container main-content mt-12">
    <Alert
      v-if="showSaveFeedback"
      class="--minimal"
      :type="showSaveFeedback"
      max-content-width
      content-class="flex-col"
    >
      <span
        v-if="showSaveFeedback === 'success'"
        data-i18n="memberSettingsUpdated"
      >{{ $t('memberSettingsUpdated') }}</span>
      <span
        v-else-if="showSaveFeedback === 'error'"
        data-i18n="memberSettingsUpdateFailed"
      >{{ $t('memberSettingsUpdateFailed') }}</span>
    </Alert>
    <ScreenWidthProvider v-slot="{ isWider }">
      <div class="settings-title">
        <div
          class="text-[1.75rem] font-bold"
          data-i18n="memberSettingsTitle"
        >
          {{ $t('memberSettingsTitle') }}
        </div>
        <p data-i18n="memberSettingsSubText">
          {{ $t('memberSettingsSubText') }}
        </p>
      </div>
      <div class="settings-wrapper flex flex-col md:flex-row">
        <div class="flex-col order-1 md:order-2 w-full">
          <div class="flex flex-col settings-how-to bg-white-blue rounded-2xl">
            <div
              class="text-2xl font-bold"
              data-i18n="memberSettingsHowToTitle"
            >
              {{ $t('memberSettingsHowToTitle') }}
            </div>
            <ol>
              <li data-i18n="memberSettingsHowToStepOne">
                {{ $t('memberSettingsHowToStepOne') }}
              </li>
              <li data-i18n="memberSettingsHowToStepTwo">
                {{ $t('memberSettingsHowToStepTwo') }}
              </li>
              <i18n-t
                data-i18n="memberSettingsHowToStepThree"
                keypath="memberSettingsHowToStepThree"
                tag="li"
              >
                <template #dot>
                  <div class="dot" />
                </template>
              </i18n-t>
              <i18n-t
                data-i18n="memberSettingsHowToStepFour"
                keypath="memberSettingsHowToStepFour"
                tag="li"
              >
                <template #button>
                  <i-singles-button
                    filled
                    :font-controlled="false"
                    alt="button"
                    height="22px"
                  />
                </template>
                <template #blue>
                  <span
                    class="text-blue font-bold"
                    data-i18n="memberSettingsHowToStepFourSpecial"
                  >{{ $t('memberSettingsHowToStepFourSpecial') }}</span>
                </template>
              </i18n-t>
              <li data-i18n="memberSettingsHowToStepFive">
                {{ $t('memberSettingsHowToStepFive') }}
              </li>
            </ol>
          </div>
        </div>
        <div class="flex-col order-2 md:order-1 w-full relative">
          <div class="settings-activate flex flex-col order-1 bg-white-blue rounded-2xl">
            <div class="flex toggle-wrapper">
              <div
                class="text-xl"
                data-i18n="memberSettingsActivateTitle"
              >
                {{ $t('memberSettingsActivateTitle') }}
              </div>
              <div class="toggle-button">
                <input
                  id="toggle"
                  v-model="payload.single_finder"
                  type="checkbox"
                />
                <label
                  v-if="payload.single_finder !== null"
                  for="toggle"
                  @touchstart="handleTouchStart"
                  @touchmove="handleTouchMove"
                  @touchend="handleTouchEnd"
                >
                  <div class="slider z-[1]" />
                  <div class="flex label-text z-[2]">
                    <span
                      :class="singleState ? 'text-white' : 'text-black'"
                      data-i18n="switchOff"
                    >{{ $t('switchOff') }}</span>
                    <span
                      :class="singleState ? 'text-black': 'text-white'"
                      data-i18n="switchOn"
                    >{{ $t('switchOn') }}</span>
                  </div>
                </label>
              </div>
            </div>
            <div
              v-if="payload.single_finder"
              class="grid-container"
            >
              <label for="age">
                {{ $t('memberSettingsActivateAge') }}
              </label>
              <input
                v-model="payload.age"
                type="number"
                name="age"
                min="0"
                max="120"
                @input="validateAge"
              />
              <p class="age-placeholder absolute">
                år
              </p>
              <label for="gender">
                {{ $t('memberSettingsActivateGender') }}
              </label>
              <select
                v-model="payload.sex"
                name="gender"
                placeholder="banan"
              >
                <option value="M">
                  {{ $t('formGenderM') }}
                </option>
                <option value="F">
                  {{ $t('formGenderF') }}
                </option>
              </select>
              <button
                class="save-button absolute btn btn-primary mt-4 rounded-[1.25rem] px-4"
                data-i18n="bookSaveChanges"
                :disabled="!dataComplete"
                @click="updateProfile"
              >
                {{ $t('bookSaveChanges') }}
              </button>
            </div>
          </div>
          <div
            v-if="profile?.list.length > 0 && singleState"
            class="settings-active-trips flex flex-col order-2 bg-teal rounded-2xl text-white mt-6"
          >
            <div
              class="h5 pb-4"
              data-i18n="memberSettingsActiveTripsTitle"
            >
              {{ $t('memberSettingsActiveTripsTitle') }}
            </div>
            <ul class="mb-0">
              <li
                v-for="(trip, index) in profile?.list"
                :key="index"
                class="settings-trip-item flex flex-col"
              >
                <div class="settings-trip-row flex pb-4">
                  <div class="flex">
                    <p class="mb-0 pr-2">
                      {{ trip.Resdatum }}
                    </p>
                    <p
                      v-if="!isWider('lg')"
                      class="trip-name mb-0"
                    >
                      {{ truncateTripName(trip.Resnamn, 14) }}
                    </p>
                    <p
                      v-else-if="isWider('2xl')"
                      class="trip-name mb-0"
                    >
                      {{ truncateTripName(trip.Resnamn, 40) }}
                    </p>
                    <p
                      v-else
                      class="trip-name mb-0"
                    >
                      {{ truncateTripName(trip.Resnamn, 24) }}
                    </p>
                  </div>
                  <div
                    class="flex cursor-pointer"
                    @click="removeTripFromList(trip.trip_id)"
                  >
                    <p
                      class="mb-0 pr-2"
                      data-i18n="memberSettingsRemoveTrip"
                    >
                      {{ $t('memberSettingsRemoveTrip') }}
                    </p>
                    <span>
                      <fa
                        icon="xmark"
                        size="xl"
                      />
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ScreenWidthProvider>
  </AuthGuard>
</template>

<script setup>
const userStore = useUserStore()

const startX = ref(0)
const currentX = ref(0)
const isDragging = ref(false)
const showSaveFeedback = ref(false)

const payload = ref({
  age: null,
  sex: null,
  single_finder: null,
})

const { data: profile } = useAsyncData(async () => {
  const result = await userStore.fetchMemberProfile()
  const list = await userStore.fetchMemberSingleFinderList()

  const config = result.config
  payload.value.age = config.age
  payload.value.sex = config.sex
  payload.value.single_finder = !!config.single_finder

  return {
    result,
    list,
  }
}, { server: false })

const singleState = computed(() => {
  return payload.value.single_finder
})

const dataComplete = computed(() => {
  return payload.value.age || payload.value.sex
})

const updateProfile = async (showFeedback) => {
  if (!dataComplete.value) {
    return
  }

  const result = await userStore.updateMemberProfile(payload.value)
  if (result) {
    const config = result.config
    payload.value.age = config.age
    payload.value.sex = config.sex
    payload.value.single_finder = !!config.single_finder

    if (showFeedback) {
      showSaveFeedback.value = 'success'
    }
    setTimeout(() => {
      showSaveFeedback.value = false
    }, 2000)
    return
  }
  showSaveFeedback.value = 'error'
  setTimeout(() => {
    showSaveFeedback.value = false
  }, 2000)
}

const handleTouchStart = (event) => {
  startX.value = event.touches[0].clientX
  isDragging.value = true
}

const handleTouchMove = (event) => {
  if (!isDragging.value) {
    return
  }

  currentX.value = event.touches[0].clientX
  const diff = currentX.value - startX.value
  if (diff > 20) {
    payload.value.single_finder = true
  } else if (diff < -20) {
    payload.value.single_finder = false
  }
}

const handleTouchEnd = () => {
  isDragging.value = false
}

watch(
  () => payload.value.single_finder,
  (_, prevValue) => {
    if (prevValue !== null) {
      updateProfile(false)
    }
  }
)

const truncateTripName = (name, length = 14) => {
  if (!name) {
    return ''
  }

  return name.length > length ? name.substring(0, length) + '...' : name
}

const validateAge = (event) => {
  const value = event.target.value
  if (value < 0 || value > 120) {
    payload.value.age = ''
  }
}

const removeTripFromList = async (tripId) => {
  const result = await userStore.memberSingleFinderDelete(tripId)
  profile.value.list = result
}
</script>

<style lang="scss" scoped>
.dot {
  width: 18px;
  height: 18px;
  background-color: theme('colors.alt-gray');
  border-radius: 50%;
  display: inline-block;
}
.settings {

  &-wrapper {
    width: 100%;
    gap: 2rem;
  }

  &-how-to {
    width: 100%;
    max-width: 505px;
    padding: 2rem;

    ol{
      padding-left: 1.5rem;

      li{
        font-size: 1rem;
        margin-bottom: 10px;
        position: relative;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  &-activate {
    width: 100%;
    max-width: 505px;
    padding: 2rem;

    .toggle-wrapper {
      justify-content: space-between;
    }
  }

  &-active-trips {
    width: 100%;
    max-width: 505px;
    padding: 2rem;
  }

  &-trip-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.31);

    &:not(:first-child) {
      padding-top: 0.5rem;
    }

    &:last-child {
      border-bottom: none;

      .settings-trip-row {
        padding-bottom: 0 !important;
      }
    }

    .trip-name {
      font-weight: 300;
    }
  }

  &-trip-row {
    justify-content: space-between;
  }
}
.toggle-button {
  position: relative;
  width: 134px;
  height: 40px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: theme('colors.medium-blue');
    transition: .4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    width: 134px;

    .label-text {
      width: 134px;
      padding: 0 22px;
      align-items: center;
      justify-content: space-between;
    }
  }
  label:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 54px;
    left: 6px;
    margin: auto 0;
    background-color: white;
    transition: .2s;
    border-radius: 56px;
  }
  input:checked + label:before {
    transform: translateX(69px);
  }
}
.grid-container {
  position: relative;
  display: grid;
  grid-template-columns: 47px 1fr;
  grid-gap: 1rem 0;
  align-items: center;
  label {
    font-weight: bold;
    justify-self: end;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  input,
  select {
    width: 116px;
    height: 27px;
    padding: 0 0.8rem;
    border: none;
    border-radius: 69px;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Hide number input arrows in Firefox */
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Hide number input arrows in Chrome, Safari, Edge, and Opera */
    margin: 0;
  }
  .age-placeholder {
    font-size: 1rem;
    color: theme('colors.alt-gray');
    top: 2px;
    left: 135px;
  }
}
.save-button {
  bottom: 0;
  right: 0;
}
</style>